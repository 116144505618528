import axios from 'axios';

let prefix = "/car-specification-topic"

const CarSpecificationTopicApi = {
	
	getCarSpecificationTopicList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getCarSpecificationTopic(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	archiveCarSpecificationTopic(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createCarSpecificationTopic(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateCarSpecificationTopic(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default CarSpecificationTopicApi;