import axios from 'axios';

let prefix = "/car-variant-color"

const CarVariantColorApi = {
	
	getCarVariantColorList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getCarVariantColor(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	archiveCarVariantColor(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createCarVariantColor(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateCarVariantColor(payload){
		return axios.post( prefix + "/update", payload)
	},
	setCarVariantColorImage(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearCarVariantColorImage(payload){
		return axios.delete( prefix + "/delete-image", {params: payload})
	},
	bulkUploadImage360(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload-image-360", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearCarVariantColorImage360(payload){
		return axios.delete( prefix + "/delete-image-360", {params: payload})
	},
}

export default CarVariantColorApi;