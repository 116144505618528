import axios from 'axios';

let prefix = "/car-specification"

const CarSpecificationApi = {
	
	getCarSpecificationList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getCarSpecification(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	archiveCarSpecification(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createCarSpecification(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateCarSpecification(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default CarSpecificationApi;