import axios from 'axios';

let prefix = "/car-list"

const CarModelApi = {
	
	getCarModelList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getCarModel(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	archiveCarModel(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createCarModel(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateCarModel(payload){
		return axios.post( prefix + "/update", payload)
	},
	setCarModelImage(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearCarModelImage(payload){
		return axios.delete( prefix + "/delete-image", {params: payload})
	},
	getTagTypes(payload){
		return axios.get( prefix + "/tag-list", {params: payload})
	},
}

export default CarModelApi;