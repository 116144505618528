import axios from 'axios';

let prefix = "/car-variant-price"

const CarVariantPriceApi = {
	
	getCarVariantPriceList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	updateCarVariantPrice(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default CarVariantPriceApi;