import axios from 'axios';

let prefix = "/car-specification-value"

const CarSpecificationValueApi = {
	
	getCarSpecificationValueList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	updateCarSpecificationValue(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default CarSpecificationValueApi;