import VueRouter from 'vue-router'
import { setPageTitle } from '@/helpers/pageTitle'

// Layout
const BaseLayout = () => import('@/views/_layouts/BaseLayout')

// Common Pages
const Comingsoon = () => import('@/views/_general/Comingsoon')
const Error404 = () => import('@/views/_general/404')
const ForgotPassword = () => import('@/views/_general/ForgotPassword')
const VerifyEmail = () => import('@/views/_general/VerifyEmail')
const Login = () => import('@/views/_general/Login')
const Register = () => import('@/views/_general/Register')
const ResetPassword = () => import('@/views/_general/ResetPassword')

// Pages
const AuthProfile = () => import('@/views/_auth/AuthProfile')
const UpdatePasswordPage = () => import('@/views/_auth/UpdatePasswordPage')

const Home = () => import('@/views/_general/Home')

const UserList = () => import('@/views/Users/UserList')
const LockUserList = () => import('@/views/Users/LockUserList')
const UserProfile = () => import('@/views/Users/UserProfile')

const SupportiveSingle = () => import('@/views/Supports/SupportiveSingle')

const FAQList = () => import('@/views/Supports/FAQ')

const AuditTrailList = () => import('@/views/AuditTrails/AuditTrailList')
const AuditTrailProfile = () => import('@/views/AuditTrails/AuditTrailProfile')

const AdvertisementList = () => import('@/views/Advertisements/AdvertisementList')

const SystemVariableList = () => import('@/views/SystemVariables/SystemVariableList')

const BroadcastList = () => import('@/views/Broadcasts/BroadcastList')

const SettingPage = () => import('@/views/SystemVariables/SettingPage')

const CarModelList = () => import('@/views/CarModels/CarModelList')
const CarModelProfile = () => import('@/views/CarModels/CarModelProfile')

const CarVariantProfile = () => import('@/views/CarVariants/CarVariantProfile')

const SubscriptionList = () => import('@/views/Subscriptions/SubscriptionList')
const SubscriptionProfile = () => import('@/views/Subscriptions/SubscriptionProfile')

const PromoCodeList = () => import( '@/views/PromoCodes/PromoCodeList')

// Routes
// ** for more information, kindy refer to https://github.com/websanova/vue-auth/blob/master/docs/Privileges.md
// ** auth: true <- can be access only if auth.check = true
// ** auth: false <- cannot be access is auth.check = true
// ** auth: null, title: '' <- no auth rule
// ** auth: [1,2] <- can be access is auth.user.role matched

const baseLayoutRoutes = [
	{ path: '/comingsoon', name: 'admin.home2', component: Comingsoon, meta: { auth: [1, 5], title: 'Coming Soon' } },
	{ path: '/home', name: 'home', component: Home, meta: { auth: true, title: 'Home' } },

	// user
	{ path: '/user/list', name: 'user.list', component: UserList, meta: { auth: true, title: 'User List', role: "Admin" } },
	{ path: '/my/profile', name: 'my.profile', component: AuthProfile, meta: { auth: true, title: 'Profile' } },
	{ path: '/user/profile/:id', name: 'user.profile', component: UserProfile, meta: { auth: true, title: 'User Profile', role: "Admin" } },
	{ path: '/user/lock-list', name: 'user.lock-list', component: LockUserList, meta: { auth: true, title: 'Lock User List', role: "Admin" } },

	// support
	// { path: '/support/:type', name: 'support.supportive_list', component: SupportiveSingle, meta: { auth: true, title: 'Supportive Info' } },
	// { path: '/support/terms-and-conditions', name: 'support.terms-and-conditions', component: SupportiveSingle, meta: { auth: true, title: 'T&C', params: { type: 'terms-and-conditions' } } },
	// { path: '/support/faq', name: 'support.faq', component: FAQList, meta: { auth: true, title: 'FAQ', params: { type: 'faq' } } },


	//audit trails
	{ path: '/audit-trail/list', name: 'audit-trail.list', component: AuditTrailList, meta: { auth: true, title: 'Audit Trail List', role: "Admin" } },
	{ path: '/audit-trail/profile/:id', name: 'audit-trail.profile', component: AuditTrailProfile, meta: { auth: true, title: 'Audit Trail Profile', role: "Admin" } },

	{ path: '/advertisement/list', name: 'advertisement.list', component: AdvertisementList, meta: { auth: true, title: 'Advertisement List' } },

	{ path: '/car-model', name: 'car-model.list', component: CarModelList, meta: { auth: true, title: 'Car Model List' } },
	{ path: '/car-model/profile/:id', name: 'car-model.profile', component: CarModelProfile, meta: { auth: true, title: 'Car Model Profile' } },

	{ path: '/car-variant/profile/:id', name: 'car-variant.profile', component: CarVariantProfile, meta: { auth: true, title: 'Car Variant Profile' } },

	// { path: '/subscription/list', name: 'subscription.list', component: SubscriptionList, meta: { auth: true, title: 'Subscription List' } },
	// { path: '/subscription/profile/:id', name: 'subscription.profile', component: SubscriptionProfile, meta: { auth: true, title: 'Subscription Profile' } },


	{ path: '/settings/list', name: 'system-variable.list', component: SystemVariableList, meta: { auth: true, title: 'System Variable List', role: "Admin" } },

	// broadcast
	// { path: '/broadcast/list', name: 'broadcast.list', component: BroadcastList, meta: { auth: true, title: 'Broadcast list' } },
	
	{ path: '/promo-code/list', name: 'promo-code.list', component: PromoCodeList, meta: { auth: true, title: 'Promo Code List', role: "Admin" } },

	{ path: '/setting', name: 'setting.page', component: SettingPage, meta: { auth: true, title: 'Settings', role: "Admin" } },
	
	{ path: '/auth/update-password', name: 'update-password.page', component: UpdatePasswordPage, meta: { auth: true, title: 'Update Password' } },
]

const routes = [

	{ path: '/', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/login', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/register/:code', name: 'register', component: Register, meta: { auth: null, title: 'Register' } },
	{ path: '/password/reset/:token', name: 'reset.password', component: ResetPassword, meta: { auth: null, title: 'Reset Password' } },
	{ path: '/forgot-password', name: 'forgot.password', component: ForgotPassword, meta: { auth: null, title: 'Forgot Password' } },
	{ path: '/email-verify/:token', name: 'verify.email', component: VerifyEmail, meta: { auth: null, title: 'Verify Email' } },

	// base_layout
	{ path: '/home', name: 'base_layout', component: BaseLayout, children: baseLayoutRoutes },

	{ path: '*', name: '404', component: Error404, meta: { auth: null, title: '404' } },
]

const router = new VueRouter({
	history: true,
	mode: 'history',
	routes,
})

router.beforeEach((to, from, next) => {
	setPageTitle(to.meta.title)
	return next()
});

export default router